import {ActionTypes} from "@/store/actions";
// import {store} from "@/store";


let connection;
/** Creating the instance for axios */
const websocketService = {

    // connect(store) {
    //
    //     console.log("Starting connection to Websocket Server")
    //     const connection = new WebSocket('ws://localhost:8723/');
    //     connection.onopen = function (event) {
    //
    //         console.log(event)
    //         console.log("Successfully connected to the websocket ws://localhost:8723/ server...")
    //         const id = websocketService.joinToChannel(connection, "tickets");
    //         console.log("Joined channel " + id + " on websocket ws://localhost:8723/ server...");
    //         localStorage.setItem("WEB_SOCKET_CONNECTION_READY_STATE", connection.readyState.toString() );
    //         store.dispatch(ActionTypes.changeConnectionStatus, connection).then(r => {console.log("done")});
    //     }
    //     connection.onmessage = function (event) {
    //         console.log("Message has been recieved...")
    //         console.log(event.data);
    //
    //         const response = JSON.parse(event.data);
    //         if (response.result.type === "join") {
    //             console.log("Age :" + response.result.info.age + " , gender: " + response.result.info.gender)
    //         }
    //         if (response.result.type === "ticket_hook") {
    //             console.log("ticketId :" + response.result.ticketId + " , event: " + response.result.event)
    //             // store.dispatch(ActionTypes.getTicket, response.result.ticketId)
    //         }
    //         if (response.result.type === "message") {
    //             console.log("messageId :" + response.result.messageId + " , text: " + response.result.text)
    //             //  store.dispatch(ActionTypes.getTicket, response.result.ticketId)
    //         } else
    //             console.log("Message :" + response.result.text)
    //     }
    //     return connection;
    // },
    waitForOpenSocketConnection(socket: WebSocket) {
        return new Promise((resolve, reject) => {
            const maxNumberOfAttempts = 10
            const intervalTime = 200 //ms
            let currentAttempt = 0
            const interval = setInterval(() => {
                if (currentAttempt > maxNumberOfAttempts - 1) {
                    clearInterval(interval)
                    reject(new Error('Maximum number of attempts exceeded'))
                } else if (socket.readyState === socket.OPEN) {
                    clearInterval(interval)
                    resolve()
                }
                currentAttempt++
            }, intervalTime)
        })
    },
    async sendMessage(socket: WebSocket, msg: string) {
        const message = '{"jsonrpc":"2.0","id":1,"method":"room/msg","params":{"id":"tickets","content":{"text":"'+msg+'"}}}'
        if (socket.readyState !== socket.OPEN) {
            try {
                await this.waitForOpenSocketConnection(socket)
                socket.send(message)
            } catch (err) {
                console.error(err)
            }
        } else {
            socket.send(message)
        }
    },
    async joinToChannel(socket: WebSocket, id: string) {
        const channelId: string = id ? id : "tickets";

        if (socket.readyState !== socket.OPEN) {
            try {
                socket.send('{"jsonrpc":"2.0","id":1,"method":"room/join","params":{"id":"'+channelId+'","info":{"user_id":"' +
                    localStorage.getItem('USER_ID') + '"}}}')
                console.log("Joined to channel : " + channelId)
            } catch (err) {
                console.error(err)
            }
        } else {

            socket.send('{"jsonrpc":"2.0","id":1,"method":"room/join","params":{"id":"'+channelId+'","info":{"user_id":"' +
                localStorage.getItem('USER_ID') + '"}}}')
            console.log("Joined to channel : " + channelId)
        }
        return id;
    },

    disconnect(connection: WebSocket) {
        console.log("Starting disconnect from Websocket Server")
        if (connection.readyState !== connection.OPEN) {
            connection.close()
        } else console.log("There is no any connection")
    }
}


export default websocketService;
