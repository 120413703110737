<template>

  <div>
  <div class="navbar-custom">
    <div class="row">
      <div class="col-md-4">
      <div style="padding-left: 50px; float: left">
      <span class="multiselect-1 is-user1" >
         <img :src="getAvatar">  Welcome {{ getUserName }}!
      </span>
      </div>
      </div>
      <div class="col-md-4">
      <div class="" >
        Tickets on-line Dashboard -  on {{day}}
      </div>
      </div>
      <div class="col-md-4">
        <div class="row" >
          <div class="col-md-6"></div>
          <div class="col-md-2" >
            <div class="dropdown">
              <a class="dropdown-toggle hidden-arrow" href="#" role="button" id="dropdownMenuLink2" data-bs-toggle="dropdown" aria-expanded="false" style="font-size:25px; cursor: pointer;"  title="Notification">
              <i class="fas fa-bell"></i><span class="badge badge-notification rounded-pill bg-danger">{{ (unreadLogsCount>0)? unreadLogsCount : ""}}</span></a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink2">
                <li><h6 class="dropdown-header">Event Log:</h6></li>
                <li v-for="(log,index) in logs" :key="index" >
                  <span @click="closeModalLogs" class="close-modal">&times;</span>
                  <span class="dropdown-item">{{ log }}</span>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-2" >
            <div class="dropdown">
              <a class="dropdown-toggle hidden-arrow" href="#" role="button" @dblclick="goToChats(user.id,null)" id="dropdownMenuLink4" data-bs-toggle="dropdown" aria-expanded="false" style="font-size:25px; cursor: pointer;"  title="Chat">
              <i class="far fa-comments"></i><span  class="badge badge-notification rounded-pill bg-danger">{{ (unreadMessageCount>0)? unreadMessageCount : ""}}</span></a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink4">
                <li><h6 class="dropdown-header">Event Log:</h6></li>
                <li v-for="(message,index) in messageLogs" :key="index">
                  <span @click="closeModalMessageLogs" class="close-modal">&times;</span>
                  <span class="dropdown-item"><a style="color: black" @click="goToChats(user.id,message.chat_id)" >{{message.createdDateFormatted}} ... {{message.author}} say: {{ message.message }}</a></span>
                </li>
              </ul>
            </div>
          </div>

<!--          <div class="col-md-2">-->
<!--            <a @dblclick="goToChats(user.id)" click=""  title = 'Chat' style="font-size:25px; cursor: pointer;"><i class="far fa-comments"></i>-->
<!--              <span class="badge badge-notification rounded-pill bg-danger">{{ (unreadMessageCount>0)? unreadMessageCount : ""}}</span></a>-->
<!--          </div>-->
          <div class="col-md-2" >
            <a href="javascript:void(0)" @click="logout" title="Logout" style="font-size:25px; float: right"><i class="fas fa-sign-out-alt"></i></a>
          </div>
        </div>
      </div>
<!--      <teleport to="#modal-area">-->
<!--        <div v-if="modalOpenedLogs" class="modal-area-log">-->
<!--          <span @click="closeModalLogs" class="close-modal">&times;</span>-->
<!--          <div class="modal-header"><h3>Logs List:</h3></div>-->
<!--          <div class="modal-body">-->
<!--            <ul class="">-->
<!--              <li v-for="(log,index) in logs" :key="index" >-->
<!--                {{ log }}-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--      </teleport>-->
    </div>
  </div>
  <nav class="navbar fixed-bottom navbar-light bg-light" style="padding: 0px 0px">
    <div class="container-fluid">
<!--      <p class="text-end">-->
      <div class="dropup clearfix" style="width: 100%">
<!--        <p class="text-end" style="margin-bottom: 0px">-->
          <a class="btn dropdown-toggle hidden-arrow float-end" href="#" role="button" id="dropdownMenuLink3" data-bs-toggle="dropdown" aria-expanded="false" style="font-size:12px;">
            logs: {{ (logs.length>0)? logs[logs.length-1]:"" }}
            <span class="badge rounded-pill bg-danger">{{ (unreadLogsCount>0)? unreadLogsCount : ""}}</span>
          </a>
<!--        </p>-->
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink3">
          <li><h6 class="dropdown-header">Event Log:</h6></li>
          <li v-for="(log,index) in logs" :key="index" >
            <span @click="closeModalLogs" class="close-modal">&times;</span>
            <span class="dropdown-item">{{ log }}</span>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  </div>
</template>

<script>
  import authService from "../services/auth.service";
  import Avatar from 'vue-avatar-component';
  import {User} from "@/models/user"
  import router from "@/router";
  import { useRoute } from 'vue-router';
  import {useStore} from "@/store";
  import {computed, ref} from "vue";
  import {MutationType} from "@/store/mutations";



  export default {
    name: "Navbar",
    props: {
      user: null,
      day: String,
    },
    setup() {
      const store = useStore();
      const routing = useRoute();
      const logs = computed(() => store.getters.getLogs);
      const chats = computed(() => store.getters.getChats);
      const messageLogs = computed(() => store.getters.getMessageLogs);
      const unreadLogsCount = computed(() => store.getters.getUnreadLogsCount);
      const unreadMessageCount = computed(() => store.getters.getUnreadMessageLogsCount);
      const modalOpenedLogs = ref(false);
      const closeModalLogs = () =>{
        store.commit(MutationType.resetUnreadLogsCount)
      };
      const modalOpenedMessageLogs = ref(false);
      const closeModalMessageLogs = () =>{
        store.commit(MutationType.resetUnreadMessageLogsCount)
      };
      const goToChats = (id,chatId) => {

        if ( routing.name === 'chats') {
        // if (router.default.currentRoute._rawValue.name === 'chats') {
          if(chatId !== null && chatId !== undefined) {
            const idx = Math.max(0, chats.value.findIndex(chat => Number(chat.id) === Number(chatId)))
            if (idx > 0) {
              store.commit(MutationType.setActiveChatId, idx);
              store.commit(MutationType.setActiveChat, chats.value[idx]);
            }
          }
          return ;
        }
        else if (chatId !== null && chatId !== undefined)
          router.push( {name: 'chats', params: { id: id, chatId: chatId } })
        else router.push( {name: 'chats', params: { id: id , chatId: "all"} })
      }
      return {goToChats, logs, messageLogs, unreadLogsCount,  closeModalLogs, closeModalMessageLogs, unreadMessageCount}
    },
    computed: {

      getUserName() {
        if (this.user)
          return this.user.username;
        if (localStorage.getItem("CONTACT_NAME"))
          return localStorage.getItem("CONTACT_NAME");
        else return "Guest user";
      },
      getAvatar() {

        if (this.user)
          return this.user.avatar_link;
        if (localStorage.getItem("AVATAR_LINK"))
          return localStorage.getItem("AVATAR_LINK");
        else return "https://extranet.ronabooking.com/Images/no_user.jpg";
      },
    },
    methods: {
      logout() {
        authService.logout();
        router.push("login");
      }
    }
  }
</script>

<style lang="scss" scoped>
.dropdown-menu-right {
  right: 0;
  left: auto;
}
.dropdown-menu-left {
  left: 0;
  right: auto;
}
.dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #262626;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.hidden-arrow.dropdown-toggle:after {
  display: none;
}
.badge-notification {
  position: absolute;
  font-size: .9rem;
  margin-top: -.1rem;
  margin-left: -.5rem;
  padding: .2em .45em;
}
.rounded-pill {
  border-radius: 50rem!important;
}
.modal-area {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*width: 50%;*/
  /*height: 400px;*/
  /*width: 500px;*/
  text-align: center;
  background-color: #01818c;
  color: whitesmoke;
  border-radius: 4px;
  z-index: 10000;
}
.modal-area-log {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*width: 50%;*/
  /*height: 400px;*/
  /*width: 500px;*/
  text-align: center;
  background-color: #01818c;
  color: whitesmoke;
  border-radius: 4px;
  z-index: 10000;
}
.multiselect-1.is-user1 img {
  width: 35px;
  border-radius: 50%;
  height: 35px;
  border: 2px solid #ffffff;
}
  .navbar-custom {
    height: 50px;
    width: 100%;
    font-size: 21px;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #01818c;
    color: #FFF;
    padding: 8px 15px;
    transition: font-weight 0.3s;
    z-index: 5;

    a {
      color: #FFF;
    }
  }
  .modal-area-nav {
    position: absolute;
    top: 50%;
    bottom: 50%;
    height: 400px;
    width: 500px;
    transform: translate(-50%, -50%);
    text-align: center;
  }

</style>
