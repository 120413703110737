<template>
  <keep-alive>
    <div class="default-layout">
      <navbar :user="user" :day="currentFormattedDay"/>
      <div class="container-fluid">
<!--        <div class="" style="width: 100%; padding-top: 50px;">-->
          <vue-element-loading
              :active="loading"
              is-full-screen
              spinner="bar-fade-scale"
              text="Please wait..."
              color="#F58B3D"
              background-color = "rgba(255, 255, 255, .7)"
              size="56"/>
        <router-view/>
<!--      </div>-->
        <float-menu
            :position="'top left'"
            :dimension="40"
            :menu-data="items"
            :on-selected="handleSelection"
            :menu-dimension="{ width: 200, height: 250}"
            :theme="{
              primary: '#F58B3D',
              menuBgColor: 'whitesmoke',
              textSelectedColor: '#01818c',
              hoverBackground: '#ddecee',
            }"
        >
          <template #tickets>
            <div style="margin-left: 10px"><i class="fas fa-clipboard-list fa-lg"></i></div>
          </template>
          <template #apartments>
            <div style="margin-left: 10px"><i class="fas fa-hotel fa-lg"></i></div>
          </template>
          <template #finance>
            <div style="margin-left: 10px"><i class="fas fa-euro-sign fa-lg"></i></div>
          </template>
          <template #workers>
            <div style="margin-left: 10px"><i class="fas fa-user-friends fa-lg"></i></div>
          </template>
          <template #owners>
            <div style="margin-left: 10px"><i class="fas fa-house-user fa-lg"></i></div>
          </template>
          <template #settings>
            <div style="margin-left: 10px"><i class="fas fa-cogs fa-lg"></i></div>
          </template>
          <i class="fas fa-bars fa-lg" style="cursor:pointer"></i>
        </float-menu>
  </div>
    </div>
  </keep-alive>
</template>

<script>
import websocketService from "../services/websocket.service";
import Navbar from "./Navbar";
import {computed, onMounted} from "vue";
import {useStore} from "@/store";
import {ActionTypes} from "@/store/actions";
import VueElementLoading from "vue-element-loading";
import { FloatMenu } from "vue-float-menu";
import "vue-float-menu/dist/vue-float-menu.css";
import {MutationType} from "@/store/mutations";


import router from "../router";
import {MutationTypes} from "../types/mutation-types";

// const separator = {
//   template: `<hr style="border-color: rgba(0, 0, 0, 0.1); margin: 20px;">`
// }
export default {
    name: "DefaultLayout",
    setup() {
      const store = useStore();
      const loading = computed(() => store.getters.loading);
      const user = computed(() => store.getters.getUser);
      const WEB_SOCKET_SWF_LOCATION = "/swf/WebSocketMain.swf";
      const WEB_SOCKET_DEBUG = true;
      const name = "";
      const currentFormattedDay = computed(() => store.getters.currentFormattedDay);
      const isGuest = computed(() => {
        return (localStorage.getItem("USER_ID") === undefined || localStorage.getItem("USER_ID") < 0);
      })
      const handleSelection = (selectedItem) => {
       switch (selectedItem) {
        case "Apartments": router.push( {name: 'apartments' }); break;
        case "Tickets": router.push( {name: 'tickets' }); break;
        case "Owners": router.push( {name: 'owners' }); break;
        case "Workers": router.push( {name: 'workers' }); break;
       }

      };
      onMounted(async () => {
        await store.dispatch(ActionTypes.getUser);
        console.log("Starting connection to Websocket Server")
        const ws = new WebSocket('ws://173.212.241.125:1337/broadcast');
        ws.onopen = function (event) {
          // const login_data = '{"type":"login","client_name":"'+user.value.username.replace(/"/g, '\\"')+'","room_id":1}';
          // console.log("websocket joining:"+login_data);
          // ws.send(login_data);
          // console.log("Successfully connected to the websocket ws://173.212.241.125:1337/ server...")
          // const id = websocketService.joinToChannel(ws, "tickets");
          // console.log("Joined channel " + id + " on websocket ws://173.212.241.125:1337/ server...");
          localStorage.setItem("WEB_SOCKET_CONNECTION_READY_STATE", ws.readyState.toString());
          store.dispatch(ActionTypes.changeConnectionStatus, ws).then(r => {
            console.log("done")
          });
        }
        ws.onmessage = function (event) {
          console.log("Message has been recieved...")
          console.log(event.data);
          const response = event.data;
          const index = response.indexOf(": {");
          if (index > 0 ) {
            const json = response.substring(index + 2, response.length);
            const data = JSON.parse(json);

            switch (data.type) {
              case 'ping':
                ws.send('{"type":"pong"}');
                break;
              case 'login':
                console.log(data.message.content);
                break;
              case 'message':
               store.dispatch(ActionTypes.getMessage, data.message.id);
               store.commit(MutationType.addMessageLog,data.message)
               break;
              case 'chat':
               store.dispatch(ActionTypes.getMessage, data.message.id);
               store.commit(MutationType.addMessageLog,data.message)
               break;
              case 'ticket':
                store.dispatch(ActionTypes.getTicket, data.id)
                store.commit(MutationType.addLog,data.content)
                break;
              case 'location':
                store.dispatch(ActionTypes.getUserLocation, data.id)
                break;
              case 'voice':
                console.log("voice");
                break;
            }
          }
        }
        store.dispatch(ActionTypes.setConnection, ws);

      });
      return {
        store,
        // menu,
        user,
        currentFormattedDay,
        isGuest,
        handleSelection,
        loading
      }
    },
    components: {Navbar, VueElementLoading, FloatMenu },
    component: () => import( './Navbar.vue'),

  data () {
    return {
      day: (new Date()).toLocaleDateString(),


      items: [
        // {
          // name: 'Dashboard',
          // subMenu: {
          //   name: "edit-items",
          //   items: [{ name: "Copy" }, { name: "Paste" }],
          // },
        // },
        {
          name: 'Tickets',
          iconSlot: "tickets",
        },

        {
          name: 'Apartments',
          iconSlot: 'apartments',
        },
        {
          name: 'Owners',
          iconSlot: 'owners',
        },
        {
          name: 'Workers',
          iconSlot: 'workers',
        },
        {divider: true},
        {
          name: 'Finance',
          iconSlot: 'finance',
        },
        {divider: true},
        {
          name: 'Settings',
          iconSlot: 'settings',
        },
      ],
    }
  },

}

</script>


<style lang="scss">

body,
html {
  margin: 0;
  padding: 0;
}
.menu-head-wrapper {
  top: 5px!important;
  left: 15px;
}
.menu-list .name {
  padding-left: 1rem;
  font-weight: bold;
}

pre {
  font-family: Consolas, monospace;
  color: #000;
  background: #fff;
  border-radius: 2px;
  padding: 15px;
  line-height: 1.5;
  overflow: auto;
}
</style>




<style lang="scss" scoped>
  //#default-layout {
  //  margin: 0;
  //  padding: 0;
  //  padding-top: 0px;
  //  background-color: whitesmoke;
  //}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.card-custom {
  z-index: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px 10px 10px 10px;
  border-radius: 5px;
  background-color: #fff;
  border-color: #fff;
  -webkit-box-shadow: inset 0px 0px 1px -2px rgba(0, 0, 0,.20), 0 2px 2px 0 rgba(0, 0, 0,.14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  box-shadow: inset 0px 0px 1px -2px rgba(0, 0, 0,.20), 0 2px 2px 0 rgba(0, 0, 0,.14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  width: 100%;
}
.table-header {
  padding: 8px 10px 18px 10px; color: #f26c0d; font-weight: bolder; background-color: whitesmoke;
  margin: 0px 0px -10px 0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.panel-heading {
  position: fixed;
  z-index: 2;
  width: 100%;
  /*background-color: #EFF6F7;*/
  background-color: #ddecee;
  color: #03808c;
  border-bottom: 4px solid #01818c;
  padding: 10px 15px;
}
a:hover {
  color: #f26c0d;
}
.worker-tag {
  background: #01818c;
  display: inline-flex !important;
}
.multiselect-tag.is-user {
  padding: 5px 8px;
  border-radius: 22px;
  background: #01818c;
  margin: 3px 3px 8px;
}
.multiselect-tag.is-user img {
  width: 18px;
  border-radius: 50%;
  height: 18px;
  margin-right: 8px;
  border: 2px solid #ffffff;
}
.multiselect-tag.is-user i:before {
  color: #ffffff;
  border-radius: 50%;
}
.user-avatar {
  margin: 0 6px 0 0;
  border-radius: 20%;
  height: 22px;
}
.avatar-header {
  width: 35px;
  border-radius: 50%;
  height: 35px;
  margin-right: 8px;
  border: 2px solid #ffffff;
}
.custom-label {
  background-color: #F58B3D ;
  display: inline-block;
  padding: 0.3em 0.6em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: whitesmoke;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  margin-left: 5px;
  cursor: pointer;
}
.info {
  background-color: #01818c ;
}
.warning {
  background-color: #F58B3D ;
}
.close-modal {
  float: right;
  font-size: 21px;
  font-weight: bold;
  color: #fff;
  opacity: 0.6;
  position: absolute; right:10px;
  cursor: pointer;
}
.modal-area {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*width: 50%;*/
  /*height: 400px;*/
  /*width: 500px;*/
  text-align: center;
  background-color: #01818c;
  color: whitesmoke;
  border-radius: 4px;
  z-index: 10000;
}
.modal-big {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0%);
  /*height: 400px;*/
  /*width: 900px;*/
  width: 80%;
  text-align: center;
  background-color: white;
  border: 1px solid grey;
  /*color: whitesmoke;*/
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 5px rgba(110, 117, 124, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 5px rgba(110, 117, 124, 0.6);
}
.close-modal-dark {
  color: black;
}

.form-control {
  /*padding: 0;*/
  padding: 8px 14px 8px 14px;
}
.customer-detail {
  border: 1px solid whitesmoke;
  position: relative;
  padding: 5px 28px 25px;
  border-radius: 10px;
  background: #01818c;
  margin: 13px 13px 13px;
  color: whitesmoke;
}
.customer-detail-white {
  border: 1px solid #01818c;
  position: relative;
  padding: 5px 28px 25px;
  border-radius: 10px;
  background: whitesmoke;
  margin: 13px 13px 13px;
  /*color: whitesmoke;*/
}

.multiselect-tag {
  background: #01818c;
}
.is-multiple .multiselect-search input, .is-single .multiselect-search input {
  background: white;
}
.multiselect-multiple-label, .multiselect-placeholder, .multiselect-single-label {
  color: #777;
}
.btn-info, .btn-primary, .btn-success {
  background-color: #01818c ;
  color: white;
}
.btn-warning {
  background-color: #F58B3D;
  color: white;
}
.btn-info:hover, .btn-info:focus,
.btn-success:hover, .btn-success:focus,
.btn-primary:hover, .btn-primary:focus {
  background-color: #01747f;
  background-position: 0 -15px;
  color: white;
}
.btn-info:active, .btn-info.active,
.btn-success:active, .btn-success.active,
.btn-primary:active, .btn-primary.active {
  background-color: #F58B3D;
}

.btn-warning:active, .btn-warning.active {
  background-color: #01818c;
  color: white;
}
.btn-warning:hover, .btn-warning:focus {
  background-color: #f26c0d;
  background-position: 0 -15px;
  color: white;
}
.navbar-item .form-control {
  background-color: transparent;
}
.footer-form {
  display: block;
  /*display: flex; if right mode*/
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
}
.header-form {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}
.custom {
  width: 200px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  /*background-image: url('https://p7.hiclipart.com/preview/825/205/159/computer-icons-web-search-engine-website-download-search-png-simple.jpg');*/
  background-position: 10px 10px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  /*padding: 12px 20px 12px 40px;*/
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}
 .custom:focus  {
  width: 100%;
}

   /* width */
 ::-webkit-scrollbar {
   width: 5px;
 }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #93918f;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F58B3D;
}
/*custom file input*/
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Choose file(s)';
  display: inline-block;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
}
.custom-file-input:hover::before {
  /*border-color: black;*/
}
.custom-file-input:active::before {
  /*background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);*/
}
</style>

